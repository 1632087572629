@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap");
body {
  padding: 0;
  margin: 0;
}

.menu {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

.menu .logo {
  padding: 2.25rem;
}

.menu .logo > p {
  font-size: 1rem;
  color: #333;
}

.menu .logo img {
  width: 40%;
}

.menu .inputs {
  width: 100%;
  padding: 2.25rem;
}

.menu .inputs .btn-pumpkin {
  background-color: #2a1244;
  color: #ff9501;
  font-weight: bold;
  padding: 16px;
}

.menu .btn-pumpkin:hover {
  background-color: #ff9501;
  color: #2a1244;
}

.menu .footer {
  text-align: center;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #dddddd;
}

.form-control {
  width: 100% !important;
  height: 58px !important;
  font-family: "Work Sans" !important;
}

.btn-pumpkin {
  background-color: #8f53a1 !important;
  color: white !important;
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 23px !important;
}

.logo p{
    font-family: "Work Sans" !important;
}
input::placeholder{
    font-family: "Work Sans" !important;
}
select::placeholder{
    font-family: "Work Sans" !important;
}
label{
    font-family: "Work Sans" !important; 
}
#erro{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    color: #FF1B1B;
}

.form-floating {
  display: flex !important;
}

.input-group-text {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  font-family: "Work Sans" !important;
}
.border {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  font-family: "Work Sans" !important;
}

.pd{
  padding: 0;
  padding-top: 0.625rem !important;
  font-family: "Work Sans" !important;
  font-size: 1rem !important;
}
  .form-floating{
    height: 58px;
  }